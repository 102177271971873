/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
const RealEstate = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/real-estate/",
        name: "Online Electronic Signatures for Real Estate | Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/real-estate/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Document eSign online solution to connect and automate the entire real estate transaction process.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/real-estate/"],
          },
        ],
      },
    ],
  }
  return (
    <>
      <Layout rawData={{ className: "sticky-header" }}>
        <SEO
          title="Online Electronic Signatures for Real Estate | Document eSign"
          description="Document ESign online solution to connect and automate the entire real estate transaction process."
          schemaMarkup={schema}
        />
        <div className="intro real-estate-intro bg-blue-light ani-in show-on-scroll">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 py-4 py-lg-5 ani-top">
                <h1 className="pt-1">
                  Real estate eSignature and document signing solutions with
                  Document eSign
                </h1>
                <p className="mt-4 pt-1 fw-regular">
                  Get through the lengthy paperwork process of real estate
                  transactions on a single platform, accelerating the process of
                  your documentation. Sign real estate documents from any device
                  in seconds. Document eSign provides a compliant, safe and easy
                  way to close property deals faster for Real estate
                  professionals.{" "}
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark mt-4 mb-2 px-md-5"
                >
                  Get started
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lights">
          <div className="container py-5 mt-lg-4">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix(
                      "images/accelerate-deal-contracts.jpg"
                    )}
                    className="card-img-top lazyload"
                    alt="Accelerate deal contracts
                    "
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-chart-line"></i>
                    </div>
                    <h4>Accelerate deal contracts</h4>
                    <p className="card-text">
                      Sign and send residential or commercial lease agreements,
                      manage the sale, and purchase agreements through real
                      estate agents, property managers, or a broker in just a
                      click. Get all the documents together in no time,
                      eliminating a long-drawn and error-filled process.
                      Document eSign workflow automation uses online form links,
                      SMS, or APIs for more authentication.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix(
                      "images/faster-efficient-transactions.jpg"
                    )}
                    className="card-img-top lazyload"
                    alt="faster efficient transactions"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-money-check-alt"></i>
                    </div>
                    <h4>Faster and efficient transactions</h4>
                    <p className="card-text">
                      Document eSign enables cutting down the close time of any
                      deal considerably. We incorporate an easy to use the
                      method of adding documents, sending an email invitation to
                      the party, and{" "}
                      <Link to="/document-workflows/">
                        manage your document workflow
                      </Link>{" "}
                      with some other custom features. The convenience of
                      signing, re-signing, and delivering revised agreements
                      considerably reduce the closing time from weeks to days.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/faster-contract-review.jpg")}
                    className="card-img-top lazyload"
                    alt="faster contract review"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-tasks"></i>
                    </div>
                    <h4>Faster contract review and process</h4>
                    <p className="card-text">
                      Commute to work or travel while preparing your real estate
                      documents conveniently. Save time and effort by signing
                      rental agreements from any device. Automate the signing
                      process by using kiosks on your phones to get agreements
                      filled and signed while renting out apartments, and close
                      deals in seconds.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix(
                      "images/legally-binding-auditable.jpg"
                    )}
                    className="card-img-top lazyload"
                    alt="legally binding auditable"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-gavel"></i>
                    </div>
                    <h4>Legally binding and auditable</h4>
                    <p className="card-text">
                      Stay compliant and secure with Document eSign’s automation
                      workflow for filling out and signing multiple documents.
                      Use legally binding solutions to prevent malpractice, any
                      activities in the document between the two signers are
                      easily tracked and maintained. Signed lease and purchase
                      agreements can be stored and retrieved in the cloud
                      solution, ensuring security with two-factor authentication
                      and advanced encryption.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix(
                      "images/reduce-cost-hassles-errors.jpg"
                    )}
                    className="card-img-top lazyload"
                    alt="reduce cost hassles errors"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-chart-area"></i>
                    </div>
                    <h4>Reduce cost, hassles and errors</h4>
                    <p className="card-text">
                      Eliminate the cycle of printing, scanning, and sending
                      documents for generating faster revenues and save time and
                      effort. Real estate brokers, agents, buyers, and sellers
                      can authorize a sale conveniently. Avoid lengthy
                      paperworks and close a deal without any errors. Our{" "}
                      <Link to="/free-document-esign-online/">
                        eSignature online services{" "}
                      </Link>
                      help eliminate all the hassles by sorting documents and
                      sending out for signing the documents in order at once.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/any-device.jpg")}
                    className="card-img-top lazyload"
                    alt="any device"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-mobile-alt"></i>
                    </div>
                    <h4>Customers eSign from any device</h4>
                    <p className="card-text">
                      Document eSign enables you to sign a document and seal a
                      deal successfully while on-the-go. Clients will get
                      notified about instructions on how to process with the
                      signing. Manage the signing process and stay updated about
                      the progress of the document. Save time and effort by
                      signing a document from anywhere, anytime from any device,
                      and stay ahead of the competitors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default RealEstate
